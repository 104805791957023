<template>
  <v-app id="inspire">
    <v-content>
      <v-toolbar dark color="primary">
        <v-toolbar-title>회원가입</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn flat :to="{name: '로그인'}">로그인으로 이동</v-btn>
        </v-toolbar-items>
        
      </v-toolbar>
      <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <v-form ref="form" v-model="forms.valid" lazy-validation>
                <v-card class="elevation-12">
                  <v-card-text>
                    
                      <v-text-field
                        prepend-icon="person"
                        name="login"
                        label="이메일"
                        type="text"
                        v-model="forms.email"
                        :rules="options.emailRules"
                        ></v-text-field>
                      <v-text-field
                        id="password"
                        prepend-icon="lock"
                        name="password"
                        label="비밀번호"
                        v-model="forms.password"
                        :rules="options.passwordRules"
                        :append-icon="show1 ? 'visibility_off' : 'visibility'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                      <v-text-field
                        id="confirm"
                        prepend-icon="lock"
                        name="conform"
                        label="비밀번호 확인"
                        v-model="options.confirm"
                        :rules="options.confirmRules"
                        :append-icon="show2 ? 'visibility_off' : 'visibility'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="!forms.valid" @click="onRegister" :loading="isLoading">회원가입</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-flex>
          </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      show1: false,
      show2: false,
      isLoading: false,
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref
      },
      forms: {
        email: '',
        password: ''        
      },
      options: {
        confirm: '',
        valid: false,
        passwordRules: [
          v => !!v || '비밀번호를 입력하세요',
          v => (v && v.length >= 6) || '비밀번호는 6자 이상입니다'
        ],
        confirmRules: [
          v => !!v || '비밀번호를 한번 더 입력하세요',
          (v) => v === this.forms.password || '비밀번호가 맞지 않습니다'
        ],
        emailRules: [
          v => !!v || '이메일을 입력하세요',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '이메일을 형식에 맞게 입력하세요'
        ]
      }
    }
  },
  methods: {
    onRegister () {
      this.$log.error(this.query.ref.loginHistory)
      if (this.$refs.form.validate()) {
        this.onSignUp()
      }
    },
    onSignUp () {
      // let loader = this.$loading.show()
      const _this = this
     
      this.query.firebase.auth().createUserWithEmailAndPassword(this.forms.email, this.forms.password)
        .then(firebaseUser => {
          const newUser = {
            uid: firebaseUser.user.uid,
            email: _this.forms.email,
            password: _this.forms.password,
            isActive: false,
            created: _this.moment().format(),
            endday: '',
            isAdmin: false,
            isSuperAdmin: false,
            lastConnection: _this.moment().format(),
            userMemo: ''
          }
          const payload = {
            created: _this.moment().format(), // new Date().getTime(),
            uid: '',
            email: this.forms.email,
            password: this.forms.password,
            isSuccess: true,
            errorCode: '',
            message: '',
            position: 'register',
            program: 'web'
          }
          this.query.firebase.database().ref(this.query.ref.users).child(firebaseUser.user.uid).update(newUser)
            .then(() => {
              this.onSaveHistory(payload)
              /*
              const user = this.query.firebase.auth().currentUser;
              user.sendEmailVerification().then(function() {
                // Email sent.
              }).catch( function (error) {
                // An error happened.
              })
              */
            })
            //.catch(e => console.error('error inserting', 'dt', e))
          // loader.hide()
          this.$toasted.show('회원가입 되었습니다', {type: 'success'})
        })
        .catch((e) => {
          const payload = {
            created: _this.moment().format(), // new Date().getTime(),
            uid: '',
            email: _this.forms.email,
            password: _this.forms.password,
            isSuccess: false,
            errorCode: e.code,
            message: e.message,
            position: 'register',
            program: 'web'
          }
          const errorMsg = {
            errorCode: e.code,
            default: '회원가입 중 오류가 발생했습니다'
          }
          this.$store.dispatch('firebaseError', errorMsg).then((v) => {
            this.$toasted.show(v, {type: 'error'})
          })
          this.isLoading=false
          this.onSaveHistory(payload)
        })
    },
    onSaveHistory (payload) {
      const _this = this
      this.$log.error('onSaveHistory')
      this.query.firebase.database().ref(this.query.ref.history).push(payload)
        .then(() => {
          _this.$router.push({ name: '로그인' })
        })
        .catch(e => this.$log.warn('error inserting', 'dt', e))
    }
  }
}
</script>
